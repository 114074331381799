import React, { useContext } from 'react';
import { useRouter } from 'next/router';

import Beacon from '@/assets/scripts/Beacon';

import { viewportContext } from '@/context/viewport.context';

import WithAccountLayout from '@/layouts/withAccount.layout';
import WithLocationLayout from '@/layouts/withLocation.layout';
import WithModalLayout from '@/layouts/withModal.layout';
import WithToastLayout from '@/layouts/withToast.layout';
import WithViewportLayout from '@/layouts/withViewport.layout';

import { themeHelpers } from '@/utils/helpers';

const withStandardLayout = ({ ...props }) => {
  const router = useRouter();
  const { isMobile } = useContext(viewportContext);

  return (
    <div className="pageRoot" data-theme={themeHelpers.getTheme(router)}>
      <WithViewportLayout>
        <WithToastLayout>
          <WithLocationLayout>
            <WithAccountLayout>
              <WithModalLayout>
                {!isMobile && <Beacon />}
                {props.children}
              </WithModalLayout>
            </WithAccountLayout>
          </WithLocationLayout>
        </WithToastLayout>
      </WithViewportLayout>
    </div>
  );
};

export default withStandardLayout;
