import React, { useContext, useEffect, useState, useRef } from 'react';
import dynamic from 'next/dynamic';
const Header = dynamic(() => import('@/components/header/Header'), { ssr: false });
const Footer = dynamic(() => import('@/components/footer/Footer'), { ssr: false });
import WithStandardLayout from '@/layouts/withStandard.layout';
import Language from '@/utils/Language';
import PropTypes from 'prop-types';
import LoginPage from '@/components/LoginPage/LoginPage';
import initializeIconLibrary from '@/utils/fontAwesomeLibrary';
import 'bootstrap/dist/css/bootstrap.min.css';
import useAccount from '@/hooks/useAccount';

initializeIconLibrary();

const Login = (props) => {
  const [lang, setLang] = useState(Language.getCurrentLanguage);
  const { account } = useAccount();

  const languageHandler = (lang) => {
    Language.setCurrentLanguage(lang);
    setLang(lang);
  };

  useEffect(() => {
    account.setNextPage('/');
  }, []);

  return (
    <>
      <Header lang={lang} languageHandler={languageHandler} />
      <LoginPage />
      <Footer />
    </>
  );
};

export const getServerSideProps = () => {
  return { props: {} };
};

Login.getLayout = function getLayout(page) {
  return <WithStandardLayout>{page}</WithStandardLayout>;
};

export default Login;
