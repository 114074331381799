import React, { useEffect } from 'react';
import useAccount from '@/hooks/useAccount';
import Language from '@/utils/Language';
import { initBeacon, renderHelpScout } from '@/utils/utils';

const Botlist = ["Googlebot", "Google-InspectionTool", "Pingdom", "Crawler", "YandexBot", "EasouSpider", "MJ12bot", "XoviBot", "Slurp", "bingbot", "Baiduspider", "Facebook", "Yandex", "Twitter"];

// add 'staging' or 'development' to the array to see the chat box in the respective environment
const activeEnvironments = ['production'];

const Beacon = () => {
  const { account } = useAccount();

  const checkProStatus = () => {
    return account?.data?.is_professional || false
  }

  // Do not display on Family Resource Finder
  const isFRF = () => {
    if (window.location.pathname.includes('familyresourcefinder')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if(activeEnvironments.indexOf(process.env.NODE_ENV) < 0) return;
    if (!navigator.userAgent.toLowerCase().match(new RegExp(Botlist.join('|').toLowerCase())) && !isFRF()) {
      initBeacon();
      const isPro = checkProStatus()
      if (isPro) {
        renderHelpScout('pro');
      } else {
        renderHelpScout(Language.getCurrentLanguage());
      }
    }
  }, [account?.data?.is_professional]);

  return <></>;
}

export default Beacon;
