import React, { useEffect } from 'react';
import classes from './LoginPage.module.scss';
import { Container } from 'react-bootstrap';
import t from '@/utils/Language/Modal/LoginModal-translations';
import useAccount from '@/hooks/useAccount';
import useSaml from '@/hooks/useSaml';
import ContentPane from '@/components/ContentPane/ContentPane';
import LoginForm from './LoginForm';

const LoginPage = ({ ...props }) => {
  const { displayGoogleButton, account } = useAccount();
  const { samlPartner } = useSaml();

  useEffect(() => {
    account.setNextPage('/');
    if (samlPartner === false) {
      displayGoogleButton('googleButtonContainer');
    }
  }, [samlPartner]);

  return (
    <ContentPane>
      <Container className={classes.loginPage} {...props}>
        <div className={classes.pageHeader}>
          <span className={classes.pageTitle}>
            <h4>{t('login_title')}</h4>
          </span>
          {samlPartner === false && <div id="googleButtonContainer" className={classes.btnGoogle}></div>}
        </div>
        <div className={classes.pageBody}>
          <LoginForm context={'page'} />
        </div>
      </Container>
    </ContentPane>
  );
};

export default LoginPage;
